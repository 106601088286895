import React from 'react'; // eslint-disable-line import/no-extraneous-dependencies
import Helmet from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Header from '../components/Header';
import Footer from '../components/Footer';

import Page from '../components/Page';
import LogosBlock from '../components/Logos/block';
import Location from '../components/Location';

import styles from './cases.module.css';

const CasesPage = ({ data }) => (
  <div>
    <Helmet
      title={`${data.site.siteMetadata.title} - Cases`}
      meta={[
        {
          name: 'description',
          content: 'To learn what we don’t know. To solve hard challenges. To enjoy the journey.',
        },
        {
          name: 'keywords',
          content:
            'javascript, js, consulting, big data, hadoop, spark, machine learning, tensor flow, react, angular, web, html, css, design, ux',
        },
      ]}
    >
      <html lang="sv" />
      <link rel="icon" href="/favicon.ico?v=2" />
    </Helmet>
    <div>
      <Header className={styles.headerBackground} />
      <Page bgColor="#FFFCF7">
        <section className={styles.sectionHeader}>
          <h1 style={{color: '#F7A416'}} >Cases</h1>
          <h2>Client cases with our amazing clients</h2>
          <p>During the years we have worked with a multitude of interesting cases that requires we utilize our expertise in problem solving. Here are a few of them.</p>
        </section>
        <main>
          <div className={styles.line} />
          {data.allCasesJson.edges
            .map((x) => x.node)
            .map((caseData, i) => {
              if (i % 2 == 0) {
                return (
                  <section key={caseData.url} className={styles.caseSection}>
                    <div className={styles.caseIllustration}>
                      <img src={`/images/${caseData.image}`} />
                    </div>
                    <div className={styles.caseText}>
                      <h3>{caseData.title}</h3>
                      <p>{caseData.shortDescription}</p>
                      <Link
                        to={`/cases/${caseData.url}`}
                        className={styles.link}
                      >
                        Read all about it >>
                      </Link>
                    </div>
                  </section>
                );
              } else {
                return (
                  <section key={caseData.url} className={styles.caseSection}>
                    <div className={styles.caseText}>
                      <h3>{caseData.title}</h3>
                      <p>{caseData.shortDescription}</p>
                      <Link
                        to={`/cases/${caseData.url}`}
                        className={styles.link}
                      >
                        Read all about it >>
                      </Link>
                    </div>
                    <div className={styles.caseIllustration}>
                      <img src={`/images/${caseData.image}`} />
                    </div>
                  </section>
                );
              }
            })}
        </main>
      </Page>
      <LogosBlock
        id="logos"
        logos={data.allLogosJson.edges.map((x) => x.node)}
      />
      <Location title={data.site.siteMetadata.title} id="location" />
      <Footer
        products={data.allProductsJson.edges.map((x) => x.node)}
        cases={data.allCasesJson.edges.map((x) => x.node)}
        title={data.site.siteMetadata.title}
      />
    </div>
  </div>
);

export default CasesPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allLogosJson {
      edges {
        node {
          src
          alt
        }
      }
    }
    allCasesJson {
      edges {
        node {
          url
          title
          shortTitle
          shortDescription
          image
        }
      }
    }
    allProductsJson {
      edges {
        node {
          url
          shortTitle
        }
      }
    }
  }
`;
